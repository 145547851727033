import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

const ProjectSortButton = ({ _text, _onClickMethod, _isActive }) => {
    return (
        <Hover hoverClass={styles.sort_button_hovered}>
            <button
                type="button"
                onClick={_onClickMethod}
                className={
                    _isActive
                        ? `${styles.sort_button} ${styles.sort_button_active}`
                        : styles.sort_button
                }
            >
                {_text}
            </button>
        </Hover>
    )
}

ProjectSortButton.propTypes = {
    _text: PropTypes.string.isRequired,
    _onClickMethod: PropTypes.func.isRequired,
    _isActive: PropTypes.bool.isRequired,
}

export default ProjectSortButton
