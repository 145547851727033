import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Seo from '../components/utils/seo'

import IndexPageComponent from '../components/page/indexPageComponent'

const IndexPage: React.FC<PageProps<GatsbyTypes.IndexPageQuery>> = ({ data }) => {
    return (
        <IndexPageComponent
            _dataIndex={data.indexQuery}
            _dataCommon={data.optionQuery.edges[0].node.themeOptions.common}
        />
    )
}

export default IndexPage

export const Head = () => <Seo _title="" />

export const pageQuery = graphql`
    query IndexPage($myFilter: WpPostFilterInput) {
        indexQuery: allWpPost(filter: $myFilter, sort: { order: DESC, fields: date }) {
            edges {
                node {
                    title
                    id
                    slug
                    date(formatString: "YMMDDHHmmss")
                    worksContents {
                        roleField {
                            bausUrl
                            directInput {
                                role
                            }
                            relLink {
                                title
                                url
                            }
                        }
                        noLength
                        videoLength {
                            hour
                            minutes
                            seconds
                        }
                        projectColor
                        textColor
                        contentsRepeaterField {
                            subTitle
                            contentField {
                                mediaType
                                vimeoId
                                youtubeId
                                showVideoLinkUrl
                                isAutoPlay
                                videoRatio {
                                    width
                                    height
                                }
                                videoPlaceholder {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(
                                                formats: [AUTO, WEBP]
                                                breakpoints: [686, 1568, 1736, 1920]
                                                width: 1920
                                                outputPixelDensities: 2
                                            )
                                        }
                                    }
                                }
                                textEn
                                textJp
                                imageSlider {
                                    planeImage {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    formats: [AUTO, WEBP]
                                                    breakpoints: [750, 1568, 1800, 1920]
                                                    width: 1920
                                                    outputPixelDensities: 2
                                                )
                                            }
                                        }
                                    }
                                }
                                imageListWithSpace
                                imageList {
                                    imageType
                                    vimeoGif
                                    planeImage {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    formats: [AUTO, WEBP]
                                                    breakpoints: [750, 1568, 1800, 1920]
                                                    width: 1920
                                                    outputPixelDensities: 2
                                                )
                                            }
                                        }
                                    }
                                    gifImage {
                                        localFile {
                                            publicURL
                                        }
                                        width
                                        height
                                    }
                                    video {
                                        localFile {
                                            publicURL
                                        }
                                    }
                                    vimeoId
                                    youtubeId
                                    showVideoLinkUrl
                                    isAutoPlay
                                    videoRatio {
                                        width
                                        height
                                    }
                                    videoPlaceholder {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    formats: [AUTO, WEBP]
                                                    breakpoints: [686, 1568, 1736, 1920]
                                                    width: 1920
                                                    outputPixelDensities: 2
                                                )
                                            }
                                        }
                                    }
                                }
                                imageListHalf {
                                    imageType
                                    vimeoGif
                                    planeImage {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    formats: [AUTO, WEBP]
                                                    breakpoints: [750, 1800]
                                                    width: 1800
                                                    outputPixelDensities: 2
                                                )
                                            }
                                        }
                                    }
                                    gifImage {
                                        localFile {
                                            publicURL
                                        }
                                        width
                                        height
                                    }
                                    video {
                                        localFile {
                                            publicURL
                                        }
                                    }
                                    vimeoId
                                    youtubeId
                                    isAutoPlay
                                    showVideoLinkUrl
                                    videoRatio {
                                        width
                                        height
                                    }
                                    videoPlaceholder {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    formats: [AUTO, WEBP]
                                                    breakpoints: [686, 1568, 1736, 1920]
                                                    width: 1920
                                                    outputPixelDensities: 2
                                                )
                                            }
                                        }
                                    }
                                }
                                imageListOneThird {
                                    imageType
                                    vimeoGif
                                    planeImage {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    formats: [AUTO, WEBP]
                                                    breakpoints: [750, 1800]
                                                    width: 1800
                                                    outputPixelDensities: 2
                                                )
                                            }
                                        }
                                    }
                                    gifImage {
                                        localFile {
                                            publicURL
                                        }
                                        width
                                        height
                                    }
                                    video {
                                        localFile {
                                            publicURL
                                        }
                                    }
                                    vimeoId
                                    youtubeId
                                    showVideoLinkUrl
                                    isAutoPlay
                                    videoRatio {
                                        width
                                        height
                                    }
                                    videoPlaceholder {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(
                                                    formats: [AUTO, WEBP]
                                                    breakpoints: [686, 1568, 1736, 1920]
                                                    width: 1920
                                                    outputPixelDensities: 2
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    worksThumbnail {
                        thumbnailGif {
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
        optionQuery: allWp {
            edges {
                node {
                    themeOptions {
                        common {
                            topVideo
                            topVideoUrl
                            topVideoFrame {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: [AUTO, WEBP]
                                            breakpoints: [750, 1800, 2560]
                                            width: 2560
                                            outputPixelDensities: 2
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
