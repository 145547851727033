import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import * as styles from './style.module.sass'

import Image from '../../utils/image'
import CopyButton from '../copyButton'

const TopMainVideo = ({ _dataCommon }) => {
    const [isPlaying, setIsPlaying] = useState(false)

    const onPlayCallback = () => {
        setIsPlaying(true)
    }

    const getVideoStyle = () => {
        let className = `${styles.top_main_video__body}`
        if (isPlaying) className += ` ${styles.top_main_video__body_is_show}`
        return className
    }

    return (
        <div className={styles.top_main_video}>
            <div className={styles.top_main_video__image}>
                <Image
                    _data={_dataCommon.topVideoFrame.localFile}
                    _alt="top main video placeholder"
                />
            </div>
            <ReactPlayer
                url={`https://vimeo.com/${_dataCommon.topVideo}`}
                muted
                playsinline
                loop
                playing
                onPlay={onPlayCallback}
                className={getVideoStyle()}
                width="100%"
                height="100%"
            />
            {_dataCommon.topVideoUrl && (
                <div className={styles.top_main_video__copy_link}>
                    <CopyButton _link={_dataCommon.topVideoUrl} _hasBackground />
                </div>
            )}
        </div>
    )
}

export default TopMainVideo

TopMainVideo.propTypes = {
    _dataCommon: PropTypes.shape({
        topVideo: PropTypes.string.isRequired,
        topVideoUrl: PropTypes.string.isRequired,
        topVideoFrame: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.oneOfType([
                        PropTypes.shape({}),
                        PropTypes.arrayOf(PropTypes.shape({})),
                    ]),
                }),
            }),
        }),
    }).isRequired,
}
