import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './style.module.sass'

import TopMainVideo from '../../ui/topMainVideo'
import ProjectList from '../../model/projectList'

const IndexPageComponent = ({ _dataIndex, _dataCommon }) => {
    return (
        <div className={styles.index}>
            {_dataCommon.topVideo ? (
                <div className={styles.index__main_video}>
                    <TopMainVideo _dataCommon={_dataCommon} />
                </div>
            ) : (
                <hr className={styles.index__no_video_separator} />
            )}
            <ProjectList _dataIndex={_dataIndex} />
        </div>
    )
}

export default IndexPageComponent

IndexPageComponent.propTypes = {
    _dataIndex: PropTypes.shape({
        edges: PropTypes.arrayOf(
            PropTypes.shape({
                node: PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    id: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    date: PropTypes.string.isRequired,
                    worksContents: PropTypes.shape({
                        noLength: PropTypes.bool,
                        videoLength: PropTypes.shape({
                            hour: PropTypes.number,
                            minutes: PropTypes.number,
                            seconds: PropTypes.number,
                        }).isRequired,
                        roleField: PropTypes.shape({
                            bausUrl: PropTypes.string,
                            directInput: PropTypes.arrayOf(
                                PropTypes.shape({
                                    role: PropTypes.string,
                                })
                            ),
                            relLink: PropTypes.shape({
                                title: PropTypes.string,
                                url: PropTypes.string,
                            }),
                        }),
                        projectColor: PropTypes.string.isRequired,
                        textColor: PropTypes.string,
                        contentsRepeaterField: PropTypes.arrayOf(
                            PropTypes.shape({
                                subTitle: PropTypes.string,
                                contentField: PropTypes.arrayOf(
                                    PropTypes.shape({
                                        mediaType: PropTypes.string.isRequired,
                                        vimeoId: PropTypes.string,
                                        youtubeId: PropTypes.string,
                                        isAutoPlay: PropTypes.bool,
                                        showVideoLinkUrl: PropTypes.string,
                                        videoRatio: PropTypes.shape({
                                            width: PropTypes.number,
                                            height: PropTypes.number,
                                        }),
                                        videoPlaceholder: PropTypes.shape({
                                            localFile: PropTypes.shape({
                                                childImageSharp: PropTypes.shape({
                                                    gatsbyImageData: PropTypes.oneOfType([
                                                        PropTypes.shape({}),
                                                        PropTypes.arrayOf(PropTypes.shape({})),
                                                    ]),
                                                }),
                                            }),
                                        }),
                                        textEn: PropTypes.string,
                                        textJp: PropTypes.string,
                                        imageSlider: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                        imageListWithSpace: PropTypes.bool,
                                        imageList: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                imageType: PropTypes.string.isRequired,
                                                vimeoGif: PropTypes.string,
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                                gifImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                    width: PropTypes.number.isRequired,
                                                    height: PropTypes.number.isRequired,
                                                }),
                                                video: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                }),
                                                vimeoId: PropTypes.string,
                                                youtubeId: PropTypes.string,
                                                isAutoPlay: PropTypes.bool,
                                                showVideoLinkUrl: PropTypes.string,
                                                videoRatio: PropTypes.shape({
                                                    width: PropTypes.number,
                                                    height: PropTypes.number,
                                                }),
                                                videoPlaceholder: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                        imageListHalf: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                imageType: PropTypes.string.isRequired,
                                                vimeoGif: PropTypes.string,
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                                gifImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                    width: PropTypes.number.isRequired,
                                                    height: PropTypes.number.isRequired,
                                                }),
                                                video: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                }),
                                                vimeoId: PropTypes.string,
                                                youtubeId: PropTypes.string,
                                                isAutoPlay: PropTypes.bool,
                                                showVideoLinkUrl: PropTypes.string,
                                                videoRatio: PropTypes.shape({
                                                    width: PropTypes.number,
                                                    height: PropTypes.number,
                                                }),
                                                videoPlaceholder: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                        imageListOneThird: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                imageType: PropTypes.string.isRequired,
                                                vimeoGif: PropTypes.string,
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                                gifImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                    width: PropTypes.number.isRequired,
                                                    height: PropTypes.number.isRequired,
                                                }),
                                                video: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                }),
                                                vimeoId: PropTypes.string,
                                                youtubeId: PropTypes.string,
                                                isAutoPlay: PropTypes.bool,
                                                showVideoLinkUrl: PropTypes.string,
                                                videoRatio: PropTypes.shape({
                                                    width: PropTypes.number,
                                                    height: PropTypes.number,
                                                }),
                                                videoPlaceholder: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                    }).isRequired
                                ),
                            }).isRequired
                        ),
                    }).isRequired,
                    worksThumbnail: PropTypes.shape({
                        thumbnailGif: PropTypes.shape({
                            localFile: PropTypes.shape({
                                publicURL: PropTypes.string.isRequired,
                            }),
                        }),
                    }).isRequired,
                }),
            }).isRequired
        ).isRequired,
    }).isRequired,
    _dataCommon: PropTypes.shape({
        topVideo: PropTypes.string,
        topVideoUrl: PropTypes.string,
        topVideoFrame: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.oneOfType([
                        PropTypes.shape({}),
                        PropTypes.arrayOf(PropTypes.shape({})),
                    ]),
                }),
            }),
        }),
    }).isRequired,
}
