import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

import SortArrowIcon from '../../../images/sort-arrow.svg'
import SortArrowIconSp from '../../../images/sort-arrow_sp.svg'

const ProjectOrderButton = ({ _isAsc, _onClickMethod, _isActive }) => {
    return (
        <Hover hoverClass={styles.order_button_hovered}>
            <button
                type="button"
                onClick={_onClickMethod}
                className={
                    _isActive
                        ? `${styles.order_button} ${styles.order_button_active}`
                        : styles.order_button
                }
            >
                <picture
                    className={
                        _isAsc
                            ? `${styles.order_button__icon} ${styles.order_button__icon_rev}`
                            : styles.order_button__icon
                    }
                >
                    <source srcSet={SortArrowIconSp} media="(max-width: 900px)" />
                    <img src={SortArrowIcon} alt="sort arrow" />
                </picture>
                <span className={styles.order_button__line} />
            </button>
        </Hover>
    )
}

ProjectOrderButton.propTypes = {
    _isAsc: PropTypes.bool.isRequired,
    _onClickMethod: PropTypes.func.isRequired,
    _isActive: PropTypes.bool.isRequired,
}

export default ProjectOrderButton
