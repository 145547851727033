// extracted by mini-css-extract-plugin
export var project_list_item = "style-module--project_list_item--7a8ef";
export var project_list_item__content = "style-module--project_list_item__content--44edc";
export var project_list_item__content_container = "style-module--project_list_item__content_container--b65f9";
export var project_list_item__content_wrap = "style-module--project_list_item__content_wrap--a52dd";
export var project_list_item__hide_title = "style-module--project_list_item__hide_title--9c7b7";
export var project_list_item__title_wrap = "style-module--project_list_item__title_wrap--6b154";
export var project_list_item__wrap = "style-module--project_list_item__wrap--b5e95";
export var project_list_item__wrap_closing = "style-module--project_list_item__wrap_closing--a1e4f";
export var project_list_item__wrap_no_transition = "style-module--project_list_item__wrap_no_transition--1166e";
export var project_list_item__wrap_opening = "style-module--project_list_item__wrap_opening--884f3";