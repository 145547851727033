import React, { useEffect, useState, useRef } from 'react'
import { useRecoilState } from 'recoil'
import PropTypes from 'prop-types'

import { itemCloseOffsetState } from '../../../hooks/atoms/itemCloseOffset'

import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

import ProjectItemSingleTitleSection from '../projectItemTitleSection'
import ProjectSingleContent from '../../model/projectSingleContent'

import { onClickScroll } from '../../../scripts/_scrollTo'

const ProjectItemSingle = ({
    _arrayType,
    _dataProjectItem,
    _showGif,
    _key,
    _openItemKey,
    _setOpen,
    _transitionEnd,
    _setStopShowGifFunc,
}) => {
    const [isHovered, setHovered] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [refVisible, setRefVisible] = useState(false)
    const openItemRef = useRef(null)
    const openTimeout = useRef(null)
    const isVisited = useRef(false)
    const ticking = useRef(false)
    const [itemOffset, setItemOffset] = useRecoilState(itemCloseOffsetState)

    const hoverStyle = { backgroundColor: _dataProjectItem.worksContents.projectColor }

    const openItem = () => {
        setItemOffset(openItemRef.current.getBoundingClientRect().top)
        setHovered(true)
        isVisited.current = true // - カラーが塗りっぱなしにされるための変数
        setDefaultHeight() // - クリックされた瞬間にホバーされた高さを保存
        _setOpen(_key)
    }

    const closeItem = () => {
        _setOpen('')
    }

    const setDefaultHeight = () => {
        if (!refVisible) return
        const refElem = isOpen
            ? openItemRef.current
                  .getElementsByClassName(styles.project_list_item__hide_title)[0]
                  .getElementsByTagName('span')[0]
            : openItemRef.current
        openItemRef.current.setAttribute(
            'style',
            `max-height: ${refElem.getBoundingClientRect().height}px`
        )
    }

    const setOpenHeight = () => {
        const target = openItemRef.current.getElementsByTagName('div')[0]
        if (target) {
            openItemRef.current.setAttribute(
                'style',
                `max-height: ${target.getBoundingClientRect().height}px`
            )
        }
    }

    const removeMaxHeight = () => {
        openItemRef.current.setAttribute('style', '')
    }

    const resizeFunc = () => {
        if (ticking.current) return
        requestAnimationFrame(() => {
            ticking.current = false
            if (!isOpen) setOpenHeight()
        })
        ticking.current = true
    }

    const calcScrollTargetPos = (_scrollTop) => {
        const minHeight = openItemRef.current
            .getElementsByClassName(styles.project_list_item__hide_title)[0]
            .getElementsByTagName('span')[0]
            .getBoundingClientRect().height
        const maxHeight = openItemRef.current
            .getElementsByTagName('div')[0]
            .getBoundingClientRect().height
        return _scrollTop - (maxHeight - minHeight)
    }

    const closeCoreMethod = (_timeout, _onlyClose = true) => {
        setDefaultHeight() // - maxHeightを元に戻す
        setHovered(false)
        openItemRef.current.classList.add(styles.project_list_item__wrap_closing)
        setTimeout(() => {
            if (_timeout === 10) _setStopShowGifFunc(() => false)
            openItemRef.current.classList.remove(styles.project_list_item__wrap_no_transition)
            openItemRef.current.classList.remove(styles.project_list_item__wrap_closing)
            openTimeout.current = null
            if (_onlyClose) _transitionEnd()
            setIsOpen(() => false)
            removeMaxHeight()
        }, _timeout)
    }

    // - click後にProject ListのuseStateが変更したら実行されるuseEffect
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (_key === _openItemKey && !isOpen) {
                if (openTimeout.current != null) clearTimeout(openTimeout.current)
                setIsOpen(() => true)
                openItemRef.current.classList.add(styles.project_list_item__wrap_opening)
                // - open後のDOMを参照するため、timeoutで時間を稼ぐ（要環境確認）
                openTimeout.current = setTimeout(() => setOpenHeight(), 30)
                setTimeout(() => {
                    openItemRef.current.classList.remove(styles.project_list_item__wrap_opening)
                    setOpenHeight()
                    _transitionEnd()
                }, 200)
            } else if (_key !== _openItemKey && isOpen) {
                // - close途中にopenはできないので、clearTimeoutは不要
                const isJustClose = _openItemKey === ''
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
                if (isJustClose) {
                    if (openItemRef.current.getBoundingClientRect().top < 0) {
                        // - 下にいる時は閉じながらスクロールして戻る
                        onClickScroll(scrollTop + openItemRef.current.getBoundingClientRect().top)
                        closeCoreMethod(200)
                    } else {
                        // - 上にいる時は通常通り
                        closeCoreMethod(200)
                    }
                } else if (itemOffset > openItemRef.current.getBoundingClientRect().top) {
                    // - 下にいる時は一瞬で閉じさせる
                    const p = new Promise<void>((resolve) => {
                        _setStopShowGifFunc(() => true)
                        openItemRef.current.classList.add(
                            styles.project_list_item__wrap_no_transition
                        )
                        resolve()
                    })
                    p.then(() => {
                        setTimeout(() => {
                            closeCoreMethod(10, false)
                            onClickScroll(calcScrollTargetPos(scrollTop), 0)
                        }, 30)
                    })
                } else {
                    // - 上にいる時は閉じるのを少し遅らせる
                    setTimeout(() => closeCoreMethod(200, false), 30)
                }
            }
        }
    }, [_openItemKey])

    useEffect(() => {
        if (typeof window === 'undefined') return null
        window.addEventListener('resize', resizeFunc, { passive: true })
        return () => window.removeEventListener('resize', resizeFunc)
    }, [])

    return (
        <div
            className={
                // isOpen
                //     ? `${styles.project_list_item__wrap} ${styles.project_list_item__wrap_open}`
                //     : `${styles.project_list_item__wrap}`
                styles.project_list_item__wrap
            }
            ref={(el) => {
                setRefVisible(true)
                openItemRef.current = el
            }}
        >
            {isOpen ? (
                <div
                    className={styles.project_list_item}
                    style={{ backgroundColor: _dataProjectItem.worksContents.projectColor }}
                >
                    <div className={styles.project_list_item__content_wrap}>
                        <div className={styles.project_list_item__title_wrap}>
                            <ProjectItemSingleTitleSection
                                _arrayType={_arrayType}
                                _title={_dataProjectItem.title}
                                _noLength={_dataProjectItem.worksContents.noLength}
                                _hour={_dataProjectItem.worksContents.videoLength.hour}
                                _minutes={_dataProjectItem.worksContents.videoLength.minutes}
                                _seconds={_dataProjectItem.worksContents.videoLength.seconds}
                                _date={_dataProjectItem.date}
                                _gifSrc={
                                    _dataProjectItem.worksThumbnail.thumbnailGif.localFile.publicURL
                                }
                                _showGif={_showGif}
                                _isHovered={isHovered}
                                _isOpen
                                _isVisited={isVisited.current}
                                _textColor={_dataProjectItem.worksContents.textColor}
                            />
                        </div>

                        <div className={styles.project_list_item__hide_title}>
                            <ProjectItemSingleTitleSection
                                _arrayType={_arrayType}
                                _title={_dataProjectItem.title}
                                _noLength={_dataProjectItem.worksContents.noLength}
                                _hour={_dataProjectItem.worksContents.videoLength.hour}
                                _minutes={_dataProjectItem.worksContents.videoLength.minutes}
                                _seconds={_dataProjectItem.worksContents.videoLength.seconds}
                                _date={_dataProjectItem.date}
                                _gifSrc={
                                    _dataProjectItem.worksThumbnail.thumbnailGif.localFile.publicURL
                                }
                                _showGif={false}
                                _isHovered={false}
                                _isOpen={false}
                            />
                        </div>

                        <div className={styles.project_list_item__content}>
                            <div className={styles.project_list_item__content_container}>
                                <ProjectSingleContent
                                    _dataProjectItem={_dataProjectItem}
                                    _closeFunc={() => closeItem()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Hover
                    defaultStyle={isVisited.current ? hoverStyle : {}}
                    hoverStyle={hoverStyle}
                    hoverMethods={setHovered}
                    isDesktopOnly
                >
                    <button
                        type="button"
                        className={styles.project_list_item}
                        onClick={() => {
                            openItem()
                        }}
                    >
                        <ProjectItemSingleTitleSection
                            _arrayType={_arrayType}
                            _title={_dataProjectItem.title}
                            _noLength={_dataProjectItem.worksContents.noLength}
                            _hour={_dataProjectItem.worksContents.videoLength.hour}
                            _minutes={_dataProjectItem.worksContents.videoLength.minutes}
                            _seconds={_dataProjectItem.worksContents.videoLength.seconds}
                            _date={_dataProjectItem.date}
                            _gifSrc={
                                _dataProjectItem.worksThumbnail.thumbnailGif.localFile.publicURL
                            }
                            _showGif={_showGif}
                            _isHovered={isHovered}
                            _isOpen={false}
                            _isVisited={isVisited.current}
                            _textColor={_dataProjectItem.worksContents.textColor}
                        />
                    </button>
                </Hover>
            )}
        </div>
    )
}

export default ProjectItemSingle

ProjectItemSingle.defaultProps = {
    _showGif: false,
}

ProjectItemSingle.propTypes = {
    _arrayType: PropTypes.string.isRequired,
    _showGif: PropTypes.bool,
    _dataProjectItem: PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        worksContents: PropTypes.shape({
            noLength: PropTypes.bool,
            videoLength: PropTypes.shape({
                hour: PropTypes.number,
                minutes: PropTypes.number,
                seconds: PropTypes.number,
            }).isRequired,
            roleField: PropTypes.shape({
                bausUrl: PropTypes.string,
                directInput: PropTypes.arrayOf(
                    PropTypes.shape({
                        role: PropTypes.string,
                    })
                ),
                relLink: PropTypes.shape({
                    title: PropTypes.string,
                    url: PropTypes.string,
                }),
            }),
            projectColor: PropTypes.string.isRequired,
            textColor: PropTypes.string,
            contentsRepeaterField: PropTypes.arrayOf(
                PropTypes.shape({
                    subTitle: PropTypes.string,
                    contentField: PropTypes.arrayOf(
                        PropTypes.shape({
                            mediaType: PropTypes.string.isRequired,
                            vimeoId: PropTypes.string,
                            youtubeId: PropTypes.string,
                            isAutoPlay: PropTypes.bool,
                            showVideoLinkUrl: PropTypes.string,
                            videoRatio: PropTypes.shape({
                                width: PropTypes.number,
                                height: PropTypes.number,
                            }),
                            videoPlaceholder: PropTypes.shape({
                                localFile: PropTypes.shape({
                                    childImageSharp: PropTypes.shape({
                                        gatsbyImageData: PropTypes.oneOfType([
                                            PropTypes.shape({}),
                                            PropTypes.arrayOf(PropTypes.shape({})),
                                        ]),
                                    }),
                                }),
                            }),
                            textEn: PropTypes.string,
                            textJp: PropTypes.string,
                            imageSlider: PropTypes.arrayOf(
                                PropTypes.shape({
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                            imageList: PropTypes.arrayOf(
                                PropTypes.shape({
                                    imageType: PropTypes.string.isRequired,
                                    vimeoGif: PropTypes.string,
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                    gifImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                        width: PropTypes.number.isRequired,
                                        height: PropTypes.number.isRequired,
                                    }),
                                    video: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                    }),
                                    vimeoId: PropTypes.string,
                                    youtubeId: PropTypes.string,
                                    isAutoPlay: PropTypes.bool,
                                    showVideoLinkUrl: PropTypes.string,
                                    videoRatio: PropTypes.shape({
                                        width: PropTypes.number,
                                        height: PropTypes.number,
                                    }),
                                    videoPlaceholder: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                            imageListWithSpace: PropTypes.bool,
                            imageListHalf: PropTypes.arrayOf(
                                PropTypes.shape({
                                    imageType: PropTypes.string.isRequired,
                                    vimeoGif: PropTypes.string,
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                    gifImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                        width: PropTypes.number.isRequired,
                                        height: PropTypes.number.isRequired,
                                    }),
                                    video: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                    }),
                                    vimeoId: PropTypes.string,
                                    youtubeId: PropTypes.string,
                                    isAutoPlay: PropTypes.bool,
                                    showVideoLinkUrl: PropTypes.string,
                                    videoRatio: PropTypes.shape({
                                        width: PropTypes.number,
                                        height: PropTypes.number,
                                    }),
                                    videoPlaceholder: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                            imageListOneThird: PropTypes.arrayOf(
                                PropTypes.shape({
                                    imageType: PropTypes.string.isRequired,
                                    vimeoGif: PropTypes.string,
                                    planeImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                    gifImage: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                        width: PropTypes.number.isRequired,
                                        height: PropTypes.number.isRequired,
                                    }),
                                    video: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            publicURL: PropTypes.string.isRequired,
                                        }),
                                    }),
                                    vimeoId: PropTypes.string,
                                    youtubeId: PropTypes.string,
                                    isAutoPlay: PropTypes.bool,
                                    showVideoLinkUrl: PropTypes.string,
                                    videoRatio: PropTypes.shape({
                                        width: PropTypes.number,
                                        height: PropTypes.number,
                                    }),
                                    videoPlaceholder: PropTypes.shape({
                                        localFile: PropTypes.shape({
                                            childImageSharp: PropTypes.shape({
                                                gatsbyImageData: PropTypes.oneOfType([
                                                    PropTypes.shape({}),
                                                    PropTypes.arrayOf(PropTypes.shape({})),
                                                ]),
                                            }),
                                        }),
                                    }),
                                }).isRequired
                            ),
                        }).isRequired
                    ),
                }).isRequired
            ),
        }).isRequired,
        worksThumbnail: PropTypes.shape({
            thumbnailGif: PropTypes.shape({
                localFile: PropTypes.shape({
                    publicURL: PropTypes.string.isRequired,
                }),
            }),
        }).isRequired,
    }).isRequired,
    _key: PropTypes.string.isRequired,
    _openItemKey: PropTypes.string.isRequired,
    _setOpen: PropTypes.func.isRequired,
    _transitionEnd: PropTypes.func.isRequired,
    _setStopShowGifFunc: PropTypes.func.isRequired,
}
