import React, { useState, useRef, createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

import ProjectItemSingle from '../../ui/projectItemSingle'
import ProjectSortButton from '../../ui/projectSortButton'
import ProjectOrderButton from '../../ui/projectOrderButton'
import ThankYouText from '../../ui/thankYouText'

const ProjectList = ({ _dataIndex }) => {
    const projectArray = useRef(_dataIndex.edges)
    const arrayType = useRef('date')
    const arrayOrder = useRef('desc') // asc or desc
    const [dispArray, setDispArray] = useState([...projectArray.current])
    // scroll parameters
    const scrollPos = useRef(
        typeof window === 'undefined'
            ? null
            : document.body.scrollTop || document.documentElement.scrollTop
    )
    const ticking = useRef(false)
    const projectArrayRef = useRef([])
    const projectItemInfoArrayRef = useRef([])
    const [refVisible, setRefVisible] = useState(false)
    const [showGifNum, setShowGifNum] = useState({ start: -1, end: -1 })
    // open parameter
    const [openItemKey, setOpenItem] = useState('')
    const [itemTransitionEnd, setItemTransitionEnd] = useState(false)
    const [stopShowGifFunc, setStopShowGifFunc] = useState(false)
    const resizeTimer = useRef(null)

    const transitionEndFunc = (_isEnd) => {
        setItemTransitionEnd(() => _isEnd)
    }

    const getLengthArray = (_array) => {
        _array.sort((a, b) => {
            const aDiff = a.node.worksContents.noLength
                ? 0
                : Number(a.node.worksContents.videoLength.hour) * 3600 +
                  Number(a.node.worksContents.videoLength.minutes) * 60 +
                  Number(a.node.worksContents.videoLength.seconds)
            const bDiff = b.node.worksContents.noLength
                ? 0
                : Number(b.node.worksContents.videoLength.hour) * 3600 +
                  Number(b.node.worksContents.videoLength.minutes) * 60 +
                  Number(b.node.worksContents.videoLength.seconds)

            if (aDiff < bDiff) {
                return -1
            }
            if (aDiff > bDiff) {
                return 1
            }
            return 0
        })
        return _array
    }

    const getDateArray = (_array) => {
        _array.sort((a, b) => {
            const aDiff = a.node.date
            const bDiff = b.node.date

            if (aDiff < bDiff) {
                return -1
            }
            if (aDiff > bDiff) {
                return 1
            }
            return 0
        })
        return _array
    }

    const getTitleArray = (_array) => {
        _array.sort((a, b) => {
            const aDiff = a.node.title.toLowerCase()
            const bDiff = b.node.title.toLowerCase()

            if (aDiff < bDiff) {
                return -1
            }
            if (aDiff > bDiff) {
                return 1
            }
            return 0
        })
        return _array
    }

    const setLengthAsc = () => {
        setDispArray(getLengthArray([...projectArray.current]))
    }

    const setLengthDesc = () => {
        setDispArray(getLengthArray([...projectArray.current]).reverse())
    }

    const setDateAsc = () => {
        setDispArray(getDateArray([...projectArray.current]))
    }

    const setDateDesc = () => {
        setDispArray(getDateArray([...projectArray.current]).reverse())
    }

    const setTitleAsc = () => {
        setDispArray(getTitleArray([...projectArray.current]).reverse())
    }

    const setTitleDesc = () => {
        setDispArray(getTitleArray([...projectArray.current]))
    }

    const onClickLength = () => {
        if (arrayType.current === 'length') return
        if (arrayOrder.current === 'asc') setLengthAsc()
        else {
            setLengthDesc()
        }
        arrayType.current = 'length'
    }

    const onClickDate = () => {
        if (arrayType.current === 'date') return
        if (arrayOrder.current === 'asc') setDateAsc()
        else {
            setDateDesc()
        }
        arrayType.current = 'date'
    }

    const onClickTitle = () => {
        if (arrayType.current === 'title') return
        if (arrayOrder.current === 'asc') setTitleAsc()
        else {
            setTitleDesc()
        }
        arrayType.current = 'title'
    }

    const onClickAsc = () => {
        if (arrayOrder.current === 'asc') return
        if (arrayType.current === 'length') setLengthAsc()
        else if (arrayType.current === 'date') setDateAsc()
        else setTitleAsc()
        arrayOrder.current = 'asc'
    }

    const onClickDesc = () => {
        if (arrayOrder.current === 'desc') return
        if (arrayType.current === 'length') setLengthDesc()
        else if (arrayType.current === 'date') setDateDesc()
        else setTitleDesc()
        arrayOrder.current = 'desc'
    }

    const scrollFunc = () => {
        if (ticking.current || stopShowGifFunc) return
        requestAnimationFrame(() => {
            ticking.current = false
            adminShowGif()
        })
        ticking.current = true
    }

    const resizeFunc = () => {
        if (ticking.current || stopShowGifFunc) return
        requestAnimationFrame(() => {
            ticking.current = false
            if (resizeTimer.current != null) clearTimeout(resizeTimer.current)
            resizeTimer.current = setTimeout(() => {
                // - itemのmaxHeightが定まった後にresizeする
                initItemInfo()
            }, 50)
        })
        ticking.current = true
    }

    const initItemInfo = () => {
        scrollPos.current = document.body.scrollTop || document.documentElement.scrollTop
        for (let i = 0; i < projectArrayRef.current.length; i += 1) {
            const targetHeight = projectArrayRef.current[i].current.getBoundingClientRect().height
            const targetTop =
                scrollPos.current + projectArrayRef.current[i].current.getBoundingClientRect().top
            const targetBottom = targetTop + targetHeight
            projectItemInfoArrayRef.current[i] = {
                top: targetTop,
                bottom: targetBottom,
                height: targetHeight,
            }
            if (i === projectArrayRef.current.length - 1) {
                adminShowGif()
            }
        }
    }

    const updateShowGif = (_newObj) => {
        setShowGifNum(() => _newObj)
    }

    const adminShowGif = () => {
        if (stopShowGifFunc) return
        scrollPos.current = document.body.scrollTop || document.documentElement.scrollTop
        const windowBottom = scrollPos.current + window.innerHeight
        const showNum = { start: -1, end: -1 }
        for (let i = 0; i < projectArrayRef.current.length; i += 1) {
            if (projectItemInfoArrayRef.current[i] === null) break
            const targetTop = projectItemInfoArrayRef.current[i].top
            const targetBottom = projectItemInfoArrayRef.current[i].bottom
            if (
                targetTop < windowBottom &&
                scrollPos.current < targetBottom &&
                showNum.start === -1
            ) {
                showNum.start = i
            } else if (
                (targetTop >= windowBottom || scrollPos.current >= targetBottom) &&
                showNum.start !== -1 &&
                showNum.end === -1
            ) {
                showNum.end = i - 1
                if (showNum.start !== -1) {
                    updateShowGif(showNum)
                    break
                }
            }
            if (i === projectArrayRef.current.length - 1) {
                showNum.end = projectArrayRef.current.length - 1
                updateShowGif(showNum)
            }
        }
    }

    useEffect(() => {
        if (typeof window === 'undefined' || !refVisible || stopShowGifFunc) return null
        initItemInfo()
        window.addEventListener('scroll', scrollFunc, { passive: true })
        window.addEventListener('resize', resizeFunc, { passive: true })
        return () => {
            window.removeEventListener('scroll', scrollFunc)
            window.removeEventListener('resize', resizeFunc)
        }
    }, [refVisible, stopShowGifFunc])

    useEffect(() => {
        if (typeof window !== 'undefined' && refVisible && itemTransitionEnd && !stopShowGifFunc) {
            transitionEndFunc(false)
            resizeFunc()
        }
    }, [itemTransitionEnd])

    return (
        <>
            <div className={styles.project__sort_button}>
                <div className={styles.project__sort_button_group}>
                    <ProjectSortButton
                        _text="Date"
                        _onClickMethod={onClickDate}
                        _isActive={arrayType.current === 'date'}
                    />
                    <ProjectSortButton
                        _text="Length"
                        _onClickMethod={onClickLength}
                        _isActive={arrayType.current === 'length'}
                    />
                    <ProjectSortButton
                        _text="Title"
                        _onClickMethod={onClickTitle}
                        _isActive={arrayType.current === 'title'}
                    />
                </div>
                <div className={styles.project__sort_button_group}>
                    <ProjectOrderButton
                        _isActive={arrayOrder.current === 'desc'}
                        _onClickMethod={onClickDesc}
                        _isAsc={false}
                    />
                    <ProjectOrderButton
                        _isActive={arrayOrder.current === 'asc'}
                        _onClickMethod={onClickAsc}
                        _isAsc
                    />
                </div>
            </div>
            <ol>
                {dispArray.map((project, index) => (
                    <li
                        key={project.node.date}
                        className={styles.project__list_item}
                        ref={(el) => {
                            projectArrayRef.current[index] = createRef()
                            projectArrayRef.current[index].current = el
                            if (dispArray.length - 1 === index) setRefVisible(true)
                        }}
                    >
                        <ProjectItemSingle
                            _arrayType={arrayType.current}
                            _dataProjectItem={project.node}
                            _showGif={!!(showGifNum.start <= index && showGifNum.end >= index)}
                            _key={project.node.date}
                            _openItemKey={openItemKey}
                            _setOpen={setOpenItem}
                            _transitionEnd={() => transitionEndFunc(true)}
                            _setStopShowGifFunc={setStopShowGifFunc}
                        />
                    </li>
                ))}
            </ol>
            <div className={styles.project__thank_you_text_wrap}>
                <div className={styles.project__thank_you_text}>
                    <ThankYouText />
                </div>
            </div>
        </>
    )
}

ProjectList.propTypes = {
    _dataIndex: PropTypes.shape({
        edges: PropTypes.arrayOf(
            PropTypes.shape({
                node: PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    id: PropTypes.string.isRequired,
                    date: PropTypes.string.isRequired,
                    worksContents: PropTypes.shape({
                        noLength: PropTypes.bool,
                        videoLength: PropTypes.shape({
                            hour: PropTypes.number,
                            minutes: PropTypes.number,
                            seconds: PropTypes.number,
                        }).isRequired,
                        roleField: PropTypes.shape({
                            bausUrl: PropTypes.string,
                            directInput: PropTypes.arrayOf(
                                PropTypes.shape({
                                    role: PropTypes.string,
                                })
                            ),
                            relLink: PropTypes.shape({
                                title: PropTypes.string,
                                url: PropTypes.string,
                            }),
                        }),
                        projectColor: PropTypes.string.isRequired,
                        textColor: PropTypes.string,
                        contentsRepeaterField: PropTypes.arrayOf(
                            PropTypes.shape({
                                subTitle: PropTypes.string,
                                contentField: PropTypes.arrayOf(
                                    PropTypes.shape({
                                        mediaType: PropTypes.string.isRequired,
                                        vimeoId: PropTypes.string,
                                        youtubeId: PropTypes.string,
                                        isAutoPlay: PropTypes.bool,
                                        showVideoLinkUrl: PropTypes.string,
                                        videoRatio: PropTypes.shape({
                                            width: PropTypes.number,
                                            height: PropTypes.number,
                                        }),
                                        videoPlaceholder: PropTypes.shape({
                                            localFile: PropTypes.shape({
                                                childImageSharp: PropTypes.shape({
                                                    gatsbyImageData: PropTypes.oneOfType([
                                                        PropTypes.shape({}),
                                                        PropTypes.arrayOf(PropTypes.shape({})),
                                                    ]),
                                                }),
                                            }),
                                        }),
                                        textEn: PropTypes.string,
                                        textJp: PropTypes.string,
                                        imageSlider: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                        imageListWithSpace: PropTypes.bool,
                                        imageList: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                imageType: PropTypes.string.isRequired,
                                                vimeoGif: PropTypes.string,
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                                gifImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                    width: PropTypes.number.isRequired,
                                                    height: PropTypes.number.isRequired,
                                                }),
                                                video: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                }),
                                                vimeoId: PropTypes.string,
                                                youtubeId: PropTypes.string,
                                                isAutoPlay: PropTypes.bool,
                                                showVideoLinkUrl: PropTypes.string,
                                                videoRatio: PropTypes.shape({
                                                    width: PropTypes.number,
                                                    height: PropTypes.number,
                                                }),
                                                videoPlaceholder: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                        imageListHalf: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                imageType: PropTypes.string.isRequired,
                                                vimeoGif: PropTypes.string,
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                                gifImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                    width: PropTypes.number.isRequired,
                                                    height: PropTypes.number.isRequired,
                                                }),
                                                video: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                }),
                                                vimeoId: PropTypes.string,
                                                youtubeId: PropTypes.string,
                                                isAutoPlay: PropTypes.bool,
                                                showVideoLinkUrl: PropTypes.string,
                                                videoRatio: PropTypes.shape({
                                                    width: PropTypes.number,
                                                    height: PropTypes.number,
                                                }),
                                                videoPlaceholder: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                        imageListOneThird: PropTypes.arrayOf(
                                            PropTypes.shape({
                                                imageType: PropTypes.string.isRequired,
                                                vimeoGif: PropTypes.string,
                                                planeImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                                gifImage: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                    width: PropTypes.number.isRequired,
                                                    height: PropTypes.number.isRequired,
                                                }),
                                                video: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        publicURL: PropTypes.string.isRequired,
                                                    }),
                                                }),
                                                vimeoId: PropTypes.string,
                                                youtubeId: PropTypes.string,
                                                isAutoPlay: PropTypes.bool,
                                                showVideoLinkUrl: PropTypes.string,
                                                videoRatio: PropTypes.shape({
                                                    width: PropTypes.number,
                                                    height: PropTypes.number,
                                                }),
                                                videoPlaceholder: PropTypes.shape({
                                                    localFile: PropTypes.shape({
                                                        childImageSharp: PropTypes.shape({
                                                            gatsbyImageData: PropTypes.oneOfType([
                                                                PropTypes.shape({}),
                                                                PropTypes.arrayOf(
                                                                    PropTypes.shape({})
                                                                ),
                                                            ]),
                                                        }),
                                                    }),
                                                }),
                                            }).isRequired
                                        ),
                                    }).isRequired
                                ),
                            }).isRequired
                        ),
                    }).isRequired,
                    worksThumbnail: PropTypes.shape({
                        thumbnailGif: PropTypes.shape({
                            localFile: PropTypes.shape({
                                publicURL: PropTypes.string.isRequired,
                            }),
                        }),
                    }).isRequired,
                }),
            }).isRequired
        ).isRequired,
    }).isRequired,
}

export default ProjectList
